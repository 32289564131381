import request from '../utils/request';
import store from '@/store';
import qs from 'qs';
const BASE_URL = process.env.VUE_APP_BASE_GATEWAY;
const POST = (
  url,
  data,
  config = {
    baseURL: BASE_URL,
  },
) => {
  return request({
    method: 'post',
    url: url,
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    ...config,
  });
};

const GET = (url, params, config = {}) => {
  return request({
    method: 'get',
    url: url,
    params,
    ...config,
  });
};
// 获取推荐词列表
export const audiencesDetail = (data) =>
  POST(`service/gdsc/audience/audiencesDetail?_token=${store.getters.token}`, data);
// 根据选中关键词查询FB受众
export const targetingSearch = (params) => GET('ads/targetingSearch', params);
// 根据广告组获取关联商品详情
export const findSaleByAdSetId = (params, id) =>
  GET('sale/findSaleByAdSetId', params, {
    headers: { thirdLoginUserId: id },
  });
// 根据广告系列获取关联商品
export const findSaleByCampaignIds = (params) => GET('sale/findSaleByCampaignIds', params);
// 受众词翻译
export const translate = (data) =>
  POST(`/service/gtp/translate/online?_token=${store.getters.token}`, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    transformRequest: [
      function (data) {
        return qs.stringify(data);
      },
    ],
    baseURL: BASE_URL,
  });
// 谷歌趋势RSS
export const googleTrends = (geoCode) => GET(`/rss/googleTrends?geoCode=${geoCode}`);
const GASS_BASE_URL = 'https://gateway.giikin.cn';
// 热搜词
export const getHotWords = (params) =>
  GET(`/gass/google/dataStudioReporting?_token=${store.getters.token}`, params, {
    baseURL: GASS_BASE_URL,
  });
// 热搜词分类
export const getHotWordsCate = (params) =>
  GET(`/gass/google/dataStudioReporting/dataStudioReportingCategories?_token=${store.getters.token}`, params, {
    baseURL: GASS_BASE_URL,
  });
let fastBaseUrl = `http://192.168.1.115:8082`;
if (process.env.VUE_APP_API_ENV == 'production') {
  fastBaseUrl = `https://gateway.giikin.com`;
}
/**
 * 根据商品ID 获取 商品详情
 * @param {*} saleId
 * @returns
 */
export const getSaleDetails = (saleId) => GET(`${fastBaseUrl}/fastDelivery/sale/queryById?saleId=${saleId}`);
/**
 * 生成广告语
 * @param {*} saleId
 * @returns
 * https://gateway.giikin.com/fastDelivery/slogan/chatGPTSloganQuery
 */

export const createAd = (data) =>
  POST(`${fastBaseUrl}/fastDelivery/slogan/chatGPTSloganQuery`, data, { baseURL: BASE_URL, timeout: 120000 });
